import styled from 'styled-components';
import { Icon, defaultTheme } from '@amzn/storm-ui';

const LogicalOperator = styled.span`
  background-color: ${defaultTheme.palette.mercury};
  padding: 2px 8px;
  margin-top: 10px;
  border-radius: 8px;
  display: inline-block;
`;

const Alert = styled.div`
  display: flex;
  align-items: centre;
  margin-top: 10px;
`;

const AlertIcon = styled(Icon)`
  cursor: pointer;
  color: orange;
  margin: 0 5px;
`;

const SummaryViewStyled = {
  LogicalOperator,
  Alert,
  AlertIcon,
};

const SegmentRowContainer = styled.div`
  line-height: 22px;
`;

const FeatureTitleParent = styled.div`
  display: flex;
  flex-direction: column;
`;
const FeatureTitleWrapper = styled.div`
  justify-content: flex-start;
`;

export {
  FeatureTitleWrapper,
  FeatureTitleParent,
  SegmentRowContainer,
  SummaryViewStyled,
};
