/**
 * Data structure of an audience or segment(used interchangably), as returned from the public API
 */
export interface Audience {
  providerId?: string;
  audienceName: string;
  createDate?: string;
  category: string;
  updateDate?: string;
  description: string;
  audienceId: string;
  status: string;
  forecasts?: Forecasts;
  not?: boolean;
  fees?: Fee[];
  subCategory?: string;
}

/**
 * Data structure of an audience return from the public API with additional search metadata appended to it
 */
export interface AugmentedAudience extends Audience {
  rank: number;
  searchTerm: string;
  searchId: string;
}

export interface Fee {
  amount: number;
  currency: string;
  scale: number;
  feeCalculationType: string;
  impressionSupplyType: FeeTypes;
}

export enum FeeTypes {
  'VIDEO' = 'VIDEO',
  'WEB' = 'WEB',
  'STV' = 'STV',
  'STREAMING_AUDIO' = 'STREAMING_AUDIO',
  'PVA' = 'PVA',
  'OTT_VIDEO' = 'OTT_VIDEO',
}

export interface Forecasts {
  inventoryForecasts: {
    all: InventoryForecasts;
  };
}

export interface InventoryForecasts {
  dailyReach: ForecastBounds;
  dailyImpressions: ForecastBounds;
}

export interface ForecastBounds {
  lowerBoundInclusive: number;
  upperBoundExclusive: number;
}

export interface AudienceSegment {
  providerId?: string;
  audienceName: string;
  createDate?: string;
  category: string;
  updateDate?: string;
  description: string;
  audienceId: string;
  status: string;
  forecasts?: any;
  not?: boolean;
  fees?: any;
  subCategory?: string;
  id: string;
}

export interface AudienceSegmentGroup {
  dealBasedTargetingEnabled?: boolean;
  intrasetOperator: 'OR' | 'AND';
  intersetOperator: 'OR' | 'AND';
  segments: AudienceSegment[];
}

export interface TargetingGroup {
  intersetOperator: 'AND' | 'OR';
  include: AudienceSegmentGroup[];
  exclude: AudienceSegmentGroup[];
}
