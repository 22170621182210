import {AudienceDisplayFee, AvicennaAudience} from "src/model";
import {useContext} from "react";
import {FeeContext} from "src/context/FeeContext";
import {EMPTY_AUDIENCE_DISCOVERY_FEES, MISSING_FEE_STORE_CONTEXT} from "src/constants";
import {useStore} from "zustand";
import {retrieveAllAudiences} from "src/store/selectors/selectors";

export default function useAudienceDiscoveryFees(audience?: AvicennaAudience) {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const observedAudienceDiscoveryFee: AudienceDisplayFee | undefined = useStore(feeStore, (state) => {
        if (!audience || !state) {
            return undefined;
        }
        const id = audience?.targetingId || audience?.canonicalId || audience?.audienceId;
        if (id) {
            const allAudiences = retrieveAllAudiences(state);
            return allAudiences.get(id) as unknown as AudienceDisplayFee;
        }
    });

    const observeAudiencesForPricing = useStore(feeStore, (state) => state.observeAudiencesForPricing);

    return {
        audienceDiscoveryFees: observedAudienceDiscoveryFee?.audienceDiscoveryFees ?? EMPTY_AUDIENCE_DISCOVERY_FEES,
        audienceDiscovery3pFees: observedAudienceDiscoveryFee?.audienceDiscovery3pFees ?? EMPTY_AUDIENCE_DISCOVERY_FEES,
        observeAudiencesForPricing
    };
}