import React, { useCallback, useContext, useRef } from 'react';

import { MFEBootstrap } from '@mfe/state-management';
import styled from 'styled-components';
import {
  useHandleCancelEvent,
  useSaveEvent,
} from '@amzn/d16g-rodeo-card-common';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import {
  AudiencePicker,
  AudienceTargeting,
  logAudienceSuggestionsEvent,
  TaktLoggingContext,
  TaktLoggingContextType,
} from '@amzn/d16g-audience-picker-component';
import {
  ConvertFromLegacySegmentStructure,
  ConvertToLegacySegmentStructure,
  EMPTY_LEGACY_AUDIENCE_TARGETING,
} from 'src/utils/AudienceTargetingConverters';
import ApiData from 'src/MFE/lineItem/model/api';
import { AudienceTargetingGroup } from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import {
  AdTypes,
  getImpressionSupplyType,
} from 'src/utils/ImpressionSupplyType';
import { isEqual } from 'lodash-es';

/* eslint-disable */
// @ts-ignore eslint-disable
import udcTheme from '!!to-string-loader!css-loader!@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  disable3PForKindle,
  disableDemographicForVideoAmazonDeal,
  disableInMktLifestyleForVideoAmazonDeal,
} from 'src/utils/disableCallbacks';
/* eslint-enable */
import useWarningModal from 'src/MFE/hooks/useWarningModal';
import ClearAudienceModal from 'src/MFE/confirmationModal/ClearAudience';
import ReplaceAudienceModal from 'src/MFE/confirmationModal/ReplaceAudience';
import {
  getInitialState,
  setInitialState,
  setIsAudienceIncompatible,
} from 'src/utils/SimplificationUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { translateText } from 'src/Localization/LocalizationText';
import { getLocaleFromState } from 'src/Localization/LocalizationWrapper';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import { isWeblabActive, Weblabs } from 'src/utils/weblabUtil';
import { CpmTemplatePackageType } from '@amzn/d16g-state-management-interfaces/dist/v1/AagCpmTemplate';
import AudienceTargetingSettings from 'src/model/AudienceTargetingSettings';

interface AudienceEditViewContainerProps {
  /** page state that contains the paths to Audience related properties */
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>;
  setAudienceSegments: (newValue: AudienceTargetingGroup[]) => void;
  audienceSegments: AudienceTargetingGroup[];
  apiData: ApiData;
  isCard?: boolean;
  isEntityAllowListed: boolean;
  isAudienceIncompatible: boolean;
  isEntitySuggestionsAllowListed?: boolean;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
  audienceTargetingSettings: AudienceTargetingSettings;
}

interface EditViewContainerProps {
  isCard?: number;
}

const defaultProps = {
  isEntitySuggestionsAllowListed: false,
};

const EditViewContainer = styled.div<EditViewContainerProps>`
  margin: ${(props) => props.isCard && `-15px -22px`};
  border: ${(props) => !props.isCard && `solid #e7e7e7 1px;`};
  border: ${(props) => !props.isCard && `2px`};
` as any;

/**
 * Wrapper component for Audience Edit View. Currently the edit version of
 * Audience is still written in legacy JSP with react assets.
 * @param props AudienceEditViewContainerProps
 * @returns AudienceEditViewContainer
 */
export default function AudienceEditViewContainer({
  pageState,
  setAudienceSegments,
  audienceSegments,
  apiData,
  isCard,
  isEntityAllowListed,
  isAudienceIncompatible,
  isEntitySuggestionsAllowListed,
  isInclusionDisabled,
  inclusionDisabledReasonCode,
  audienceTargetingSettings,
}: AudienceEditViewContainerProps) {
  /* istanbul ignore next */
  useHandleCancelEvent(
    [(pageStateModel) => pageStateModel?.lineItemV1?.segmentTargeting?.builder],
    pageState
  );

  const { showModal: showWarningModal, ...restWarningModalProps } =
    useWarningModal();

  const isAudienceEmpty = () =>
    (audienceSegments ?? []).every(({ segments }) => segments.length === 0);

  const updateAudience = useCallback(() => {
    const updatedSets = audienceSegments.filter(
      (group) => group.segments.length
    );
    setAudienceSegments(
      updatedSets.length
        ? updatedSets
        : [EMPTY_LEGACY_AUDIENCE_TARGETING as AudienceTargetingGroup]
    );
  }, [audienceSegments, setAudienceSegments]);

  const { entityId, lineItemId, advertiserId, campaignId, suggestedSegments } =
    useContext<TaktLoggingContextType>(TaktLoggingContext);

  const handleAudienceSaved = useCallback((): Promise<void> => {
    logAudienceSuggestionsEvent({
      advertiserId,
      entityId,
      lineItemId,
      campaignId,
      suggestedSegments,
    });

    if (isEntityAllowListed && isAudienceIncompatible)
      return new Promise((resolve, reject) => {
        showWarningModal(() => {
          updateAudience();
          setInitialState(audienceSegments);
          setIsAudienceIncompatible(false);
          resolve();
        }, reject);
      });
    updateAudience();
    return Promise.resolve();
  }, [
    isEntityAllowListed,
    isAudienceIncompatible,
    audienceSegments,
    showWarningModal,
    updateAudience,
  ]);

  useSaveEvent(() => handleAudienceSaved(), [handleAudienceSaved]);
  const [bundle, isBundleLoading] = useBundle('strings');
  const audiencePickerEl = useRef(null);
  const lineItemState =
    pageState?.getCurrentPageState()?.lineItemV1 ||
    pageState?.getCurrentPageState()?.proposalV1;

  const convertedSelectedAudiences =
    ConvertFromLegacySegmentStructure(audienceSegments);

  const getTableHeight = (
    isSuggestionTableExpanded: boolean,
    calculateSuggestionTableHeight = false
  ) => {
    if (!isCard) return '600px';

    // 115 is the px height of the header and footer of the slide out edit view container
    // When suggestions are shown we reduce this to 57.5 since this will get reduced twice
    // once for discovery and for suggestions
    const EDIT_VIEW_CONTAINER = 115;

    const getElementHeight = (elementId: string) =>
      audiencePickerEl?.current?.querySelector(`#${elementId}`)?.offsetHeight ||
      0;

    let suggestionsHeader = getElementHeight('suggestions-header');
    // Add 7 for margin between the tables
    if (suggestionsHeader) suggestionsHeader += 7;

    if (calculateSuggestionTableHeight && isSuggestionTableExpanded) {
      const headerPanelHeight = getElementHeight('suggestions-header-panel');
      const footerPanelHeight = getElementHeight('suggestions-footer-panel');
      const heightToReduce =
        headerPanelHeight +
        footerPanelHeight +
        suggestionsHeader / 2 +
        EDIT_VIEW_CONTAINER / 2;

      // return suggestions table height
      return `calc(50vh - ${heightToReduce}px)`;
    }
    const headerPanelHeight = getElementHeight('header-panel');
    const filterColumnHeight = getElementHeight('filter-panel');
    const footerPanelHeight = getElementHeight('footer-panel');
    const heightToReduce =
      headerPanelHeight +
      footerPanelHeight +
      filterColumnHeight +
      (isSuggestionTableExpanded
        ? EDIT_VIEW_CONTAINER / 2 + suggestionsHeader / 2
        : suggestionsHeader + EDIT_VIEW_CONTAINER);

    // return discovery table height
    return `calc(${
      isSuggestionTableExpanded ? '50vh' : '100vh'
    } - ${heightToReduce}px)`;
  };

  const onAudienceChange = useCallback(
    (audiences: AudienceTargeting) => {
      if (isEqual(convertedSelectedAudiences, audiences)) return;

      setAudienceSegments(
        ConvertToLegacySegmentStructure(
          audiences,
          getImpressionSupplyType(lineItemState)
        )
      );
    },
    [
      lineItemState?.type,
      lineItemState?.targetingDetails?.supplySourceDetails?.supplySourceType,
      convertedSelectedAudiences,
    ]
  );

  const disableRows = useCallback(
    (data) => {
      let disabledTextID: TranslatorString;
      if (lineItemState?.type == AdTypes.KINDLE)
        disabledTextID = disable3PForKindle(data);
      else if (
        isWeblabActive(
          Weblabs.APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES
        ) &&
        lineItemState?.type === AdTypes.OTT_VIDEO &&
        lineItemState.aagCpmTemplate.aagCpmTemplate.packageType !==
          CpmTemplatePackageType.LOCAL_OTT_PREMIUM &&
        !lineItemState?.aagCpmTemplate?.aagCpmTemplate
          ?.aagCpmTemplateTargetingFeatures?.audienceTargeting1PDemo?.isIncluded
      )
        disabledTextID = disableDemographicForVideoAmazonDeal(data);
      else if (
        isWeblabActive(
          Weblabs.APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES
        ) &&
        lineItemState?.type === AdTypes.OTT_VIDEO &&
        lineItemState.aagCpmTemplate.aagCpmTemplate.packageType !==
          CpmTemplatePackageType.LOCAL_OTT_PREMIUM &&
        !lineItemState?.aagCpmTemplate?.aagCpmTemplate
          ?.aagCpmTemplateTargetingFeatures?.audienceTargeting1PIMLS?.isIncluded
      )
        disabledTextID = disableInMktLifestyleForVideoAmazonDeal(data);

      return disabledTextID ? translateText(bundle, disabledTextID) : undefined;
    },
    [
      lineItemState?.type,
      lineItemState?.aagCpmTemplate?.aagCpmTemplate
        ?.aagCpmTemplateTargetingFeatures,
    ]
  );

  if (!audienceSegments) return null;
  if (isBundleLoading) return null;
  return (
    <EditViewContainer ref={audiencePickerEl} isCard={isCard}>
      <style type="text/css">{udcTheme}</style>
      <AudiencePicker
        initialAudienceState={
          isEntityAllowListed && isAudienceIncompatible
            ? ConvertFromLegacySegmentStructure(getInitialState())
            : convertedSelectedAudiences
        }
        onChange={onAudienceChange}
        apiData={apiData}
        useLegacy={!audienceTargetingSettings.useUasSelection}
        useCategoryDropdownFilter={true}
        feeSupplyType={
          audienceTargetingSettings.useFees
            ? [getImpressionSupplyType(lineItemState)]
            : null
        }
        getTableHeight={getTableHeight}
        disableRowsCallback={disableRows}
        triggerColumnDefs={lineItemState?.type}
        isAudienceIncompatible={isAudienceIncompatible}
        isEntityAllowListed={isEntityAllowListed}
        showSuggestions={isEntitySuggestionsAllowListed}
        isInclusionDisabled={audienceTargetingSettings.isIncludeDisabled}
        inclusionDisabledReasonCode={
          audienceTargetingSettings.inclusionDisabledReasonCode
        }
        test-id="non-card-audience-picker"
        locale={getLocaleFromState(pageState)}
        maxIncludeGroups={audienceTargetingSettings.maxIncludeGroups}
        hideExcludeGroup={audienceTargetingSettings.hideExcludeGroup}
      />
      {isEntityAllowListed &&
        isAudienceIncompatible &&
        (isAudienceEmpty() ? (
          <ClearAudienceModal {...restWarningModalProps} />
        ) : (
          <ReplaceAudienceModal {...restWarningModalProps} />
        ))}
    </EditViewContainer>
  );
}

AudienceEditViewContainer.defaultProps = defaultProps;
