import { TypedPatch, MFEBootstrap } from '@mfe/state-management';
import { MFEState, Common } from '@mfe/state-management-interfaces';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import useLineItemProposalPageState from 'src/utils/hooks/useLineItemProposalPageState';
import { ComponentIds } from 'src/utils/MFEConstants';

type SetMFELoadingState = (
  componentId: ComponentIds,
  loadingState: MFEState.ComponentLoadingState
) => void;

/**
 * Hook for setting an MFE component's loading state
 * @param pageState top-level pageState object
 * @returns Function to set MFE loading state
 */
const useMFEComponentLoadingState = (
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>
): SetMFELoadingState => {
  const [, applyStatePatches] = useLineItemProposalPageState(pageState);

  const setMFELoadingState = (
    componentId: ComponentIds,
    loadingState: MFEState.ComponentLoadingState
  ): void => {
    const loadingStatePatch = new TypedPatch.TypedAddPatch(
      (state: Common.PathOf<Common.DeepPartial<LineItemProposalPageState>>) =>
        state.mfeComponentState[componentId],
      loadingState
    );
    console.debug('Setting MFE load page completed ', componentId);
    applyStatePatches([loadingStatePatch]);
  };

  return setMFELoadingState;
};

export default useMFEComponentLoadingState;
