import React from 'react';
import { LineItemElement } from '@amzn/d16g-rodeo-card-common';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import { ComponentNames } from 'src/utils/MFEConstants';
import { get } from 'lodash-es';
import MockPageStateBuilder from 'src/mock/MockPageStateBuilder';
import { mockPageStateForLineItemStandardDisplay } from 'src/mock/MockStandardDisplayLineItemState';
import AudienceTargetingContainer from 'src/MFE/lineItem/AudienceTargetingContainer';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import mockedCustomAttributes from './mock/MockCustomElementAttributes.json';
import ShadowDOMWrapper from './ShadowDOMWrapper';
import ApiData from 'src/MFE/lineItem/model/api';
import { CUSTOM_ATTRIBUTES } from 'src/utils/ApiData';
import { getKatalLogger } from 'src/utils/KatalLogger';
import {
  setIsAudienceIncompatible,
  getIsAudienceIncompatible,
  setInitialState,
} from 'src/utils/SimplificationUtils';
import { TaktLoggingContextProvider } from '@amzn/d16g-audience-picker-component';
import PricingHelper from 'src/utils/PricingHelper';
import LocalizationWrapper from '../../Localization/LocalizationWrapper';
import { FeeContextWrapper } from '@amzn/d16g-pricing-react-utils';
import AudienceTargetingSettings from 'src/model/AudienceTargetingSettings';

class AudienceTargetingMFE extends LineItemElement<LineItemProposalPageState> {
  connectedCallback() {
    super.connectedCallback();

    // With the removal of the legacy audience picker which made ajax calls,
    // these events were no longer dispatched which caused the forecasting widget to not render.
    // A temperory work around until resolved is to manually call these events
    document.dispatchEvent(new CustomEvent('ajaxSend'));
    document.dispatchEvent(new CustomEvent('ajaxComplete'));

    if (USE_MOCKS) {
      const PageStateBuilder = new MockPageStateBuilder();
      this.mfe = {
        pageState: PageStateBuilder.build(
          mockPageStateForLineItemStandardDisplay
        ),
      };
      this.setAttribute(
        'customElementAttributes',
        JSON.stringify(mockedCustomAttributes)
      );
      this.dispatchEvent(new window.Event('MFEFrameworkInitialized'));
    }
  }

  renderMFE() {
    try {
      const that = this;
      const customElementAttributes = JSON.parse(
        this.getAttribute('customElementAttributes')
      );

      const getCustomElementAttribute = (elementName) => {
        if (USE_MOCKS) return get(customElementAttributes, elementName);

        return that.getAttribute(elementName);
      };
      console.log('in the AudienceTargetingMFE.tsx');
      console.log(this.mfe.pageState.getCurrentPageState());
      const lineItemState =
        this.mfe.pageState.getCurrentPageState().lineItemV1 ||
        this.mfe.pageState.getCurrentPageState().proposalV1;

      const apiData: ApiData = {
        advertiserId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.advertiserId),
        orderId:
          getCustomElementAttribute(CUSTOM_ATTRIBUTES.campaignId) ||
          lineItemState.campaignId,
        entityId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.entityId),
        lineItemId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.lineItemId),
        marketplaceId: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.marketplaceId
        ),
        clientId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.clientId),
        country: getCustomElementAttribute(CUSTOM_ATTRIBUTES.country),
        csrfToken: getCustomElementAttribute(CUSTOM_ATTRIBUTES.csrfToken),
        defaultFilters: [
          {
            field: 'status',
            values: ['Active'],
          },
        ],
        baseURL: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryUrl
        ),
        baseUrl: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryUrl
        ),
        endpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryEndpoint
        ),
        currencyOfPreference: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.currencyOfPreference
        ),
        fxConversionRate: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.fxConversionRate
        ),
        personaBuilderUrl: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderUrl
        ),
        personaBuilderTopOverlappingEndpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderTopOverlappingEndpoint
        ),
        automatedTactic: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.automatedTactic
        ),
        locale: getCustomElementAttribute(CUSTOM_ATTRIBUTES.locale),
      };

      const isAudienceIncompatible =
        getIsAudienceIncompatible() !== undefined
          ? getIsAudienceIncompatible()
          : getCustomElementAttribute(
              CUSTOM_ATTRIBUTES.isMigrationCompatible
            ) === 'false';
      setIsAudienceIncompatible(isAudienceIncompatible);

      let segmentTargeting = USE_MOCKS
        ? getCustomElementAttribute(CUSTOM_ATTRIBUTES.segmentTargeting)
        : JSON.parse(
            getCustomElementAttribute(CUSTOM_ATTRIBUTES.segmentTargeting)
          );

      segmentTargeting =
        PricingHelper.nullAllLegacyAudienceFees(segmentTargeting);

      setInitialState(segmentTargeting?.targetingGroup?.[0]);

      const taktLoggingInitialContext = {
        entityId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.entityId),
        lineItemId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.lineItemId),
        advertiserId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.advertiserId),
        campaignId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.campaignId),
        suggestedSegments: [],
      };
      const goal = getCustomElementAttribute(CUSTOM_ATTRIBUTES.goals);

      const audienceTargetingSettings: AudienceTargetingSettings = {
        useUasSelection:
          getCustomElementAttribute(CUSTOM_ATTRIBUTES.isEntityAllowListed) ===
          'true',
        useFees: true,
        isIncludeDisabled:
          getCustomElementAttribute(CUSTOM_ATTRIBUTES.isInclusionDisabled) ===
          'true',
        inclusionDisabledReasonCode: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.inclusionDisabledTooltip
        ),
      };

      return (
        <TaktLoggingContextProvider initialState={taktLoggingInitialContext}>
          <ShadowDOMWrapper>
            <FeeContextWrapper
              pricingApiContext={{
                baseUrl: apiData.baseUrl,
                country: apiData.country,
                marketplaceId: apiData.marketplaceId,
                csrfToken: apiData.csrfToken,
                currencyOfPreference: apiData.currencyOfPreference as any,
                fxConversionRate: apiData.fxConversionRate as any,
                currencyOfMarketplace: apiData.currencyOfPreference as any,
                locale: apiData.locale,
              }}
              pageState={this.mfe?.pageState}
            >
              <LocalizationWrapper pageState={this.mfe.pageState}>
                <AudienceTargetingContainer
                  pageState={this.mfe?.pageState}
                  advertiserId={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.advertiserId
                  )}
                  apiData={apiData}
                  segmentTargeting={segmentTargeting}
                  isInternalUser={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isInternalUser
                    ) === 'true'
                  }
                  isEntityAllowListed={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isEntityAllowListed
                    ) === 'true'
                  }
                  isEntitySuggestionsAllowListed={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isEntitySuggestionsAllowListed
                    ) === 'true'
                  }
                  isAudienceIncompatible={isAudienceIncompatible}
                  isInclusionDisabled={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isInclusionDisabled
                    ) === 'true'
                  }
                  inclusionDisabledReasonCode={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.inclusionDisabledTooltip
                  )}
                  funnelStage={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.funnelStage
                  )}
                  goal={goal}
                  audienceTargetingMatchType={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.audienceTargetingMatchType
                  )}
                  isAudienceTargetingMatchTypeAllowListed={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.isAudienceTargetingMatchTypeAllowListed
                  )}
                  audienceTargetingSettings={audienceTargetingSettings}
                />
              </LocalizationWrapper>
            </FeeContextWrapper>
          </ShadowDOMWrapper>
        </TaktLoggingContextProvider>
      );
    } catch (e: any) {
      const logger = getKatalLogger();
      logger.error(
        'Card Audience Picker MFE failed to render',
        e,
        this.mfe.pageState.getCurrentPageState()
      );
    }
  }
}

customElements.define(ComponentNames.AUDIENCE_TARGETING, AudienceTargetingMFE);

export default AudienceTargetingMFE;
