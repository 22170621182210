import {
    AudienceDisplayFee,
    CustomElementSegment,
    DisplayFee,
    LineItemProposalPageState,
    Fee,
    AnyFee,
    MediaType,
} from "src/model";
import { PricingEngineScenarioAudience } from "@amzn/d16g-pricing-engine-api-type-script-client/dist-types/models/models_0";

const NULL_CURRENCY_TEXT = '--';

/**
 * Calculates the combined audience display fee based on the provided first-party and third-party display fees.
 *
 * @param {PricingEngineScenarioAudience} combinedAudience - The combined audience to populate.
 * @param {Fee[]} displayFees1P - An array of first-party display fees.
 * @param {Fee[]} displayFee3p - An array of third-party display fees.
 * @returns {AudienceDisplayFee} - The combined audience display fee.
 */
export const getCombinedAudienceDisplayFee = (
    combinedAudience: PricingEngineScenarioAudience,
    displayFees1P: AnyFee[],
    displayFee3p: AnyFee[],
): AudienceDisplayFee => {
    let max1pFee: AnyFee | undefined = displayFees1P && displayFees1P.length > 0 ? displayFees1P.reduce(getMaxFeeReducer) : undefined;
    let max3pFee: AnyFee | undefined = displayFee3p && displayFee3p.length > 0 ? displayFee3p.reduce(getMaxFeeReducer) : undefined;
    return {
        ...getMaxFeeReducer(
            max1pFee ?? {isLoading: false} as unknown as DisplayFee,
            max3pFee ?? {isLoading: false} as unknown as DisplayFee,
        ),
        audience: combinedAudience,
        firstPartyFees: max1pFee,
        thirdPartyFees: max3pFee,
        // @ts-ignore
        audienceDiscoveryFees: max1pFee?.audienceDiscoveryFees,
        // @ts-ignore
        audienceDiscovery3pFees: max3pFee?.audienceDiscovery3pFees,
    }
}

/**
 * A reducer function that calculates the maximum display fee from
 * the provided accumulator and current value.
 *
 * @param {Fee} accumulator - The accumulator display fee.
 * @param {Fee} currentValue - The current display fee.
 * @returns {Fee} - The display fee with the maximum values.
 */
export const getMaxFeeReducer = (accumulator: AnyFee, currentValue: AnyFee): AnyFee => {
    let result: AnyFee = {
        value: getMaxValue(accumulator?.range?.max?.amount, currentValue?.range?.max?.amount),
        currency: currentValue?.currency || accumulator?.currency,
        range: {
            min: {
                amount: getMinValue(accumulator?.range?.min?.amount, currentValue?.range?.min?.amount)
                //TODO: add currency
            },
            max: {
                amount: getMaxValue(accumulator?.range?.max?.amount, currentValue?.range?.max?.amount)
            },
        },
        isLoading: accumulator?.isLoading || currentValue?.isLoading,
    }

    if ("audienceDiscoveryFees" in currentValue && "audienceDiscoveryFees" in accumulator) {
        (result as AudienceDisplayFee)!.audienceDiscoveryFees = updateAudienceDiscoveryFees(currentValue?.audienceDiscoveryFees, accumulator?.audienceDiscoveryFees);
    }

    if ("audienceDiscovery3pFees" in currentValue && "audienceDiscovery3pFees" in accumulator) {
        (result as AudienceDisplayFee)!.audienceDiscovery3pFees = updateAudienceDiscoveryFees(currentValue?.audienceDiscovery3pFees, accumulator?.audienceDiscovery3pFees);
    }

    return result;
};

/**
 * @param currentFees
 * @param accumulatorFees
 * returns the max fee values to use in audience discovery
 */
function updateAudienceDiscoveryFees(
    currentFees: Map<MediaType, Fee> | undefined,
    accumulatorFees: Map<MediaType, Fee> | undefined
): Map<MediaType, Fee> | undefined {
    if (!currentFees || !accumulatorFees) return accumulatorFees;

    currentFees.forEach((fee, mediaType) => {
        const updateAccumulator = (
            fee &&
            !isNaN(Number(fee.value)) &&
            Number(fee.value) > Number(accumulatorFees.get(mediaType)?.value)
        );
        if (!accumulatorFees.has(mediaType) || updateAccumulator) {
            accumulatorFees.set(mediaType, fee);
        }
    });

    return accumulatorFees;
}

/**
 * Calculates the maximum display value between the two provided values.
 * Handles null and undefined params.
 * @param {string | undefined} value1 - The first value.
 * @param {string | undefined} value2 - The second value.
 * @returns {string} - The maximum display value.
 */
const getMaxValue = (value1: string | undefined, value2: string | undefined) => {
    if (value1 && value2) {
        return Math.max(Number(value1), Number(value2)).toFixed(2);
    }
    return value1 || value2;
}

/**
 * Calculates the minimum display value between the two provided values.
 * Handles null and undefined params.
 * @param {string | undefined} value1 - The first value.
 * @param {string | undefined} value2 - The second value.
 * @returns {string} - The minimum display value.
 */
const getMinValue = (value1: string | undefined, value2: string | undefined) => {
    if (value1 && value2) {
        return Math.min(Number(value1), Number(value2)).toFixed(2);
    }
    return value1 || value2;
}

export const getEmptyAudienceFee = (isLoading: boolean, value?: string): AudienceDisplayFee => ({
    isLoading: isLoading,
    value: value,
    currency: undefined, // TODO: Do you have to set the currency for COP to work?
    range: null,
    audience: {},
    firstPartyFees: undefined,
    thirdPartyFees: undefined
})

export const flattenSelectedAudiences = (pageState: LineItemProposalPageState): (CustomElementSegment)[] => {
    const lineItemState = pageState?.lineItemV1 || pageState?.proposalV1;
    return lineItemState?.segmentTargeting?.builder?.flatMap((group) => group.segments) as CustomElementSegment[] ?? [];
}

