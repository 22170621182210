import {AnyFee, AudienceDisplayFee, DisplayFee, Fee, MediaType} from "src/model";

export * from './lineItemTypes';
export * from './combined-audiences';

export const MISSING_PAGE_STATE_ERROR_MSG =
    'Unable to find readable page state in props. Ensure this component is passed the page state assigned to the MFE component.';

export const MISSING_FEE_STORE_CONTEXT =
    'Unable to find the FeeStore context, make sure you are wrapping your component with the FeeContextWrapper.';

export const EMPTY_FEE: Fee = {
    value: null,
    currency: null,
    range: null,
    isLoading: true,
}

export const ZERO_FEE: Fee = {
    value: "0",
    currency: null,
    range: {
        min: {
            amount: "0",
            currency: undefined,
        },
        max: {
            amount: "0",
            currency: undefined,
        }
    },
    isLoading: false,
}

export const EMPTY_DISPLAY_FEE: DisplayFee = {
    displayValue: null,
    copDisplayValue: null,
    ...EMPTY_FEE
}

export const EMPTY_AUDIENCE_DISCOVERY_FEES = new Map<MediaType, Fee>();