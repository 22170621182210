import {AudienceDisplayFee} from "src/model";
import {MISSING_FEE_STORE_CONTEXT} from "src/constants";
import {PricingApiHelper} from "src/utils/PricingApiHelper";

export function getAudienceFeeIndex(): Map<string, AudienceDisplayFee> {

    const pricingApiHelper = PricingApiHelper.getInstance();
    const feeStore = pricingApiHelper.getFeeStore();

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    return feeStore.getState().audienceFeeIndex;
}