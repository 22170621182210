import { MFEBootstrap } from '@mfe/state-management';
import { FeeStore } from 'src/store/FeeStore';
import { PricingApiHelper } from 'src/utils/PricingApiHelper';
import { useMemo, useRef } from 'react';
import usePrevious from 'src/hooks/internal/usePrevious';
import { useStore } from 'zustand';

export default function useAudienceDiscoveryFeeManager(feeStore: FeeStore) {
    const pricingApiHelper = useRef(PricingApiHelper.getInstance()).current;

    const audienceIndexSize: number = useStore(feeStore, (state) => state.audienceFeeIndex?.size) ?? 0;
    const previousAudienceIndexSize = usePrevious(audienceIndexSize);

    useMemo(() => {
        // if the audience index size has increased, request pricing for the new audiences
        // Do not request pricing if this is the first render, any prices in the index should be priced already
        if (audienceIndexSize > 0 && previousAudienceIndexSize !== undefined) {
            pricingApiHelper.requestPricingForAudienceDiscovery();
        }
    }, [audienceIndexSize]);

}