import React, { useRef, memo} from "react";
import {FeeProps} from "src/store/FeeStore";
import {PricingApiData} from "src/model/ApiData.model";
import { MFEBootstrap } from "@mfe/state-management";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import useConstant from "use-constant";
import useAudienceDiscoveryFeeManager from "src/hooks/internal/useAudienceDiscoveryFeeManager";
import { FeeContext } from "./FeeContext";

interface FeeContextWrapperProps {
    children: React.ReactNode;
    initStoreProps?: Partial<FeeProps>;
    pageState?: MFEBootstrap.PageState<any>;
    apiData?: PricingApiData;
}

const AudienceDiscoveryFeeContextWrapper = memo(function FeeContextWrapper({children, initStoreProps, apiData}: FeeContextWrapperProps) {
    useConstant(() => {
        //@ts-ignore
        PricingApiHelper.initialize(apiData, initStoreProps);
    })

    const feeContext = useRef(PricingApiHelper.getInstance().getFeeStore()).current;
    useAudienceDiscoveryFeeManager(feeContext);

    return (
        // @ts-ignore
        <FeeContext.Provider value={feeContext}>
            {children}
        </FeeContext.Provider>
    )
})

export default AudienceDiscoveryFeeContextWrapper