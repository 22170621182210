import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Audience, Filter } from '../../../models';
import { AudienceDataClient } from '../../../api';
import {
  FILTER_DROPDOWN_VIRT_COL_ID,
  TEXT_SEARCH_VIRT_COL_ID,
} from '../column/columnDefinition';
import { FilterNames } from '../../../models/FilterSet';
import { THIRD_PARTY_CATEGORIES } from '../../../constants/3p-categories';
import { EXCLUDE_AUDIENCES } from '../../../constants/translations';
import { logAudienceTargetingEvent } from '../../../utils/taktLogging';
import { TaktLoggingContextType } from '../../../state';
import { isWeblabActive, Weblabs } from '../../../utils/weblabUtil';
import { AugmentedAudience } from '../../../models/Audience';
import { v4 as uuidv4 } from 'uuid';

type FilterSetId = string;
type AudiencesCache = Map<FilterSetId, AudiencesSet>;

export interface SearchDataSourceProps {
  audienceDataClient: AudienceDataClient;
  setCurrentlyLoading: currentlyLoadingProp;
  taktContext: TaktLoggingContextType;
  searchId: string;
  setSearchId: searchIdProp;
  bulkEditMutationType?: string;
}

interface AudiencesSet {
  audiences: AugmentedAudience[];
  nextToken?: string;
  matchCount: number;
}

const UNKNOWN_NEXT_TOKEN = 'UNKNOWN NEXT TOKEN';
const PRODUCT_CATEGORY_PREFIX = 'productcategory:';
const UNKNOWN_LAST_ROW = -1;

type currentlyLoadingProp = (currentlyLoading: boolean) => void;
type searchIdProp = (currentlyLoading: string) => void;

/**
 * Requests and formats data to be put into the UDC table.
 * @param {AudienceDataClient} audienceDataClient - an instance of the Client.tsx client
 * @param {currentlyLoadingProp} setCurrentlyLoading - an instance of the Client.tsx client
 * @param {string} bulkEditMutationType(optional) - the selected mutation type for the bulk edit use-case.
 */
export class SearchDataSource implements IServerSideDatasource {
  private readonly audienceDataClient: AudienceDataClient;
  private readonly audiencesCache: AudiencesCache = new Map<
    FilterSetId,
    AudiencesSet
  >();

  private abortController?: AbortController;
  private readonly setCurrentlyLoading: currentlyLoadingProp;
  private readonly searchId: string;
  private readonly setSearchId: searchIdProp;

  private readonly bulkEditMutationType?: string;
  public succeeded: boolean = false;

  private readonly takt: TaktLoggingContextType;

  public constructor(searchDataSourceProps: SearchDataSourceProps) {
    this.audienceDataClient = searchDataSourceProps.audienceDataClient;
    this.setCurrentlyLoading = searchDataSourceProps.setCurrentlyLoading;
    this.takt = searchDataSourceProps.taktContext;
    this.searchId = searchDataSourceProps.searchId;
    this.setSearchId = searchDataSourceProps.setSearchId;
    this.bulkEditMutationType = searchDataSourceProps.bulkEditMutationType;
  }

  private buildFilterSet(
    filterModel: any,
    searchId: string,
    searchTerm: string
  ) {
    // @ts-ignore
    const filters: Filter[] = [...this.audienceDataClient.defaultFilters] || [];

    if (searchTerm) {
      const isAudienceId =
        (searchTerm.length === 18 && !isNaN(Number(searchTerm))) ||
        searchTerm.startsWith(PRODUCT_CATEGORY_PREFIX);
      filters.push({
        field: isAudienceId ? FilterNames.audienceId : FilterNames.audienceName,
        values: [searchTerm],
      });
    }

    const categoryFilter: Filter = filterModel[FILTER_DROPDOWN_VIRT_COL_ID]
      ? filterModel[FILTER_DROPDOWN_VIRT_COL_ID].value
      : undefined;
    if (categoryFilter) filters.push(categoryFilter);

    logAudienceTargetingEvent({
      metricName: 'LI_PICKER_AUDIENCE_TARGETING_SEARCH',
      advertiserId: this.takt.advertiserId,
      payload: JSON.stringify({
        ...filters,
        isPathfinderEnabled: isWeblabActive(Weblabs.PATHFINDER_SEARCH),
        searchId,
        searchTerm,
      }),
    });

    return filters;
  }

  public async getRows(params: IServerSideGetRowsParams): Promise<any> {
    try {
      if (this.abortController) this.abortController.abort();

      const startRow = params.request.startRow ?? 0;
      const endRow = params.request.endRow ?? 0;
      const filterModel = params.request.filterModel;

      const searchTerm: string = filterModel[TEXT_SEARCH_VIRT_COL_ID]
        ? filterModel[TEXT_SEARCH_VIRT_COL_ID].filter
        : undefined;

      // If the first search, then generate a new UUID. If not, then use the existing search id
      const searchId: string = startRow === 0 ? uuidv4() : this.searchId;
      this.setSearchId(searchId);

      const filters: Filter[] = this.buildFilterSet(
        filterModel,
        searchId,
        searchTerm
      ); // eslint-disable-line no-use-before-define

      // used as hash key to cache audience search results
      const filterSetId: FilterSetId = createFilterSetId(filters); // eslint-disable-line no-use-before-define

      // get audiences from cache
      let audiencesSet = this.audiencesCache.get(filterSetId);
      if (!audiencesSet) {
        audiencesSet = {
          audiences: [],
          nextToken: UNKNOWN_NEXT_TOKEN,
          matchCount: UNKNOWN_LAST_ROW,
        };
        this.audiencesCache.set(filterSetId, audiencesSet);
      }
      // if a request is made for the same data set that is shown return early with that data
      if (audiencesSet.audiences!.length >= endRow) {
        sendSuccess(startRow, endRow, audiencesSet, params.successCallback); // eslint-disable-line no-use-before-define
        return;
      }

      // get the next token for making a request for the next x amount of data
      const nextToken =
        audiencesSet.nextToken === UNKNOWN_NEXT_TOKEN
          ? undefined
          : audiencesSet.nextToken;

      // calculate maxResults needed from the api
      const audiencesNeeded = endRow - audiencesSet.audiences.length;

      params.api.showLoadingOverlay();
      this.setCurrentlyLoading(true);

      this.abortController = this.audienceDataClient.getAbortController();
      const response = this.audienceDataClient?.getAudienceSegmentsOverride
        ? await this.audienceDataClient?.getAudienceSegmentsOverride(
            filters,
            audiencesNeeded,
            this.abortController,
            nextToken
          )
        : await this.audienceDataClient?.getAudienceSegments(
            filters,
            audiencesNeeded,
            this.abortController,
            nextToken
          );
      audiencesSet.matchCount = response.matchCount; // eslint-disable-line require-atomic-updates
      audiencesSet.nextToken = response.nextToken ?? undefined; // eslint-disable-line require-atomic-updates

      response?.audiences.forEach((audience: Audience, index) => {
        if (this.bulkEditMutationType === EXCLUDE_AUDIENCES)
          if (THIRD_PARTY_CATEGORIES.includes(audience.category))
            audience.fees?.map((fee: any) => (fee.amount = 0));

        audiencesSet?.audiences.push({
          ...audience,
          searchTerm,
          searchId,
          rank: startRow + index,
        });
      });
      this.succeeded = true;
      sendSuccess(startRow, endRow, audiencesSet, params.successCallback); // eslint-disable-line no-use-before-define
    } catch (e) {
      this.succeeded = false;
      console.error(e);
      params.failCallback();
    } finally {
      params.api.hideOverlay();
      this.setCurrentlyLoading(false);
    }
  }
}

const computeSlice = (start: number, end: number, audiences: Audience[]) =>
  audiences.slice(start, Math.min(end, audiences.length));

const sendSuccess = (
  start: number,
  end: number,
  audiencesSet: AudiencesSet,
  onSuccess: (audiences: Audience[], totalAudiences: number) => void
) => {
  // only return the requests data from the cache
  const slice = computeSlice(start, end, audiencesSet.audiences);
  onSuccess(slice, audiencesSet.matchCount);
};

const createFilterSetId = (filterSet: Filter[]): FilterSetId =>
  JSON.stringify(filterSet);
