
export type MediaType = 'DISPLAY' | 'VIDEO' | 'STV';
export const AudienceDiscoveryMediaTypes: { [key: string]: MediaType } = {
    DISPLAY: 'DISPLAY',
    VIDEO: 'VIDEO',
    STV: 'STV',
};

export interface AvicennaAudience {
    targetingId?: string;
    canonicalId?: string;
    audienceId?: string;
    editPageUrl?: string;
    name: string;
    status: string;
    stateReason?: string;
    subCategory?: string;
    categoryPath?: string[];
    createDate: string;
    updateDate: string;
    restrictions: string[];
    reach?: string;
    impressions?: string;
    alert?: string;
}

export interface CombinedAvicennaAudience {
    audienceId?: string;
    editPageUrl?: string;
    name: string;
    status: string;
    stateReason?: string;
    subCategory?: string;
    categoryPath?: string[];
    createDate: string;
    updateDate: string;
    restrictions: string[];
    reach?: string;
    impressions?: string;
    alert?: string;
}