import {AudienceDisplayFee, Fee, MediaType, AudienceDiscoveryMediaTypes} from "src/model";
import {PricingEngineScenario} from "@amzn/d16g-pricing-engine-api-type-script-client";
import {setRangeForThirdPartyFees} from "src/utils/ThirdPartyAudiencePricingUtil";


export function calculateAudienceDiscoveryFees(
    currentDisplayFee: AudienceDisplayFee,
    pricingScenario: PricingEngineScenario,
): Map<MediaType, Fee> {
    let discoveryFeesMap: Map<MediaType, Fee> = new Map(
        currentDisplayFee?.audienceDiscoveryFees?.entries() ?? []
    );

    const pricingScenarioFee: Fee = {
        value: pricingScenario?.priceRange?.max?.amount,
        currency: pricingScenario?.priceRange?.max?.currency,
        isLoading: false,
        range: pricingScenario?.priceRange
    }

    switch (true) {
        case pricingScenario?.mediaTypes?.includes("DISPLAY"):
            discoveryFeesMap.set(AudienceDiscoveryMediaTypes.DISPLAY, pricingScenarioFee);
            break;

        case pricingScenario?.mediaTypes?.includes("VIDEO") && pricingScenario.supplySourceTypes?.includes("STV"):
            discoveryFeesMap.set(AudienceDiscoveryMediaTypes.STV, pricingScenarioFee);
            break;

        case pricingScenario?.mediaTypes?.includes("VIDEO"):
            discoveryFeesMap.set(AudienceDiscoveryMediaTypes.VIDEO, pricingScenarioFee);
            break;
    }

    return discoveryFeesMap;
}

export function buildAudienceDiscoveryFeeMap(value: string, currency?: string): any {
    return new Map([
        ['DISPLAY', {
            "currency": currency ?? "USD",
            "isLoading": false,
            "value": value,
            "range": setRangeForThirdPartyFees(value),
        }],
        ["VIDEO", {
            "currency": currency ?? "USD",
            "isLoading": false,
            "value": value,
            "range": setRangeForThirdPartyFees(value),
        }],
        ["STV", {
            "currency": currency ?? "USD",
            "isLoading": false,
            "value": value,
            "range": setRangeForThirdPartyFees(value),
        }],
    ])
}