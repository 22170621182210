import React, { useContext, useState } from 'react';
import { Text, defaultTheme, ThemeProvider } from '@amzn/storm-ui';
import { flatten } from 'lodash-es';
import {
  FeatureRow,
  FeatureTitle,
  SummaryView,
  SummaryViewIcon,
} from '@amzn/d16g-rodeo-website-shared-components';
import {
  AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_ALLOW_LIST_BY_ADVERTISER_ID,
  ComponentIds,
  LineItemTypes,
} from 'src/utils/MFEConstants';
import { AudienceTargetingGroup } from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import * as IdGenerator from 'src/utils/IdGenerator';
import Audience from 'src/MFE/lineItem/summary/Audience';
import { Segment } from '@amzn/d16g-state-management-interfaces/dist/v1/LineItem';
import { DeepPartial } from '@mfe/state-management-interfaces/dist/Common';
import {
  FeatureTitleParent,
  FeatureTitleWrapper,
  SegmentRowContainer,
  SummaryViewStyled,
} from './SummaryViewContainer.styled';
import {
  LocalizedText,
  translateText,
} from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import { useBundle } from '@amzn/react-arb-tools';
import TwitchSuggestion from 'src/MFE/lineItem/summary/SuggestionHelper';
import {
  getTargetedCanonicalIds,
  isTargetedAudience,
} from 'src/utils/SuggestionUtil';
import TwitchInventoryUpdatedSnackbar from 'src/MFE/lineItem/summary/TwitchInventoryUpdatedSnackbar';
import {
  AudienceCategory,
  logTwitchSuggestionEvent,
  TaktLoggingContext,
  TaktLoggingContextType,
} from '@amzn/d16g-audience-picker-component';
import { MFEBootstrap } from '@mfe/state-management';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import AudienceTargetingMatchTypeCheckbox from 'src/MFE/lineItem/summary/AudienceTargetingMatchTypeCheckbox';
import { isWeblabActive, Weblabs } from 'src/utils/weblabUtil';
import { TacticOptions } from './TacticConstants';
import styled from 'styled-components';

const textInputId = IdGenerator.generateHtmlId(
  'summaryView',
  ComponentIds.AUDIENCE_TARGETING
);

const SegmentCategorySeparator = styled(Text)`
  color: ${defaultTheme.palette.boulder};
  padding-left: ${defaultTheme.spacing.micro};
  padding-right: ${defaultTheme.spacing.micro};
`;

const SegmentCategory = styled(Text)`
  color: ${defaultTheme.palette.boulder};
`;

export enum AudienceOperatorEnum { // eslint-disable-line no-shadow
  OR = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_OR,
  AND = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AND,
}

interface SummaryViewContainerProps {
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>;
  audienceSegments: AudienceTargetingGroup[];
  isAudienceIncompatible: boolean;
  isEntityAllowListed: boolean;
  funnelStage?: string;
  goal?: string;
  twitchInInventory?: boolean;
  setTwitchInInventory: (newValue: boolean) => void;
  lineItemType: string;
  audienceTargetingMatchType?: string;
  isAudienceTargetingMatchTypeAllowListed?: string;
  tactic?: string;
}

function shouldShowAudienceTargetingMatchTypeCheckbox(
  advertiserId: string,
  isAudienceTargetingMatchTypeAllowListed,
  audienceSegments: AudienceTargetingGroup[]
): boolean {
  if (
    !isWeblabActive(
      Weblabs.D16G_RODEO_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX
    ) &&
    !AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_ALLOW_LIST_BY_ADVERTISER_ID.includes(
      advertiserId
    ) &&
    !(isAudienceTargetingMatchTypeAllowListed === 'ALLOW')
  )
    return false;

  const audienceSelected = audienceSegments.length > 0;

  const allAdvertisersAnd3pAudiences = audienceSegments.every((group) =>
    group.segments.every(
      (segment) =>
        segment.category === AudienceCategory.AdvertiserAudiences ||
        segment.category === AudienceCategory.ThirdParty
    )
  );

  return audienceSelected && !allAdvertisersAnd3pAudiences;
}

function SummaryViewContainer({
  pageState,
  audienceSegments,
  isAudienceIncompatible,
  isEntityAllowListed,
  funnelStage,
  goal,
  twitchInInventory,
  setTwitchInInventory,
  lineItemType,
  audienceTargetingMatchType,
  isAudienceTargetingMatchTypeAllowListed,
  tactic,
}: SummaryViewContainerProps) {
  const { advertiserId, lineItemId } =
    useContext<TaktLoggingContextType>(TaktLoggingContext);
  const legacyAudiencesGroups = audienceSegments || [];
  const [bundle, isBundleLoading] = useBundle('strings');
  const [showTwitchSnackbar, setShowTwitchSnackbar] = useState(false);
  const appliedTactic = TacticOptions.find((option) => option.id == tactic);

  if (isBundleLoading) return null;

  const getCategoryName = (num) =>
    translateText(
      bundle,
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_GROUP,
      ['num', `${num + 1}`]
    );

  const getCategories = () => {
    if (appliedTactic) return null;
    return legacyAudiencesGroups.map((legacyAudienceGroup, i) =>
      getCategoryName(i)
    );
  };

  const orderSegments = (unorderedSegments: DeepPartial<Segment>[]) => {
    const includes = unorderedSegments.filter((x) => !x.not);
    const excludes = unorderedSegments.filter((x) => x.not);
    return [...includes, ...excludes];
  };

  const getSummaryRows = () => {
    // if the line item has a tactic, we want to display the static identifier associated with it.
    if (appliedTactic)
      return [
        {
          widget: (
            <SegmentRowContainer>
              <Text type={'span'} fontSize={'base'}>
                {goal && (goal === 'REACH' || goal === 'TARGET_FREQUENCY')
                  ? translateText(
                      bundle,
                      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_BRAND_PLUS_TACTIC_IDENTIFIER
                    )
                  : translateText(bundle, appliedTactic.description)}
              </Text>
              <SegmentCategorySeparator type={'span'} fontSize={'base'}>
                -
              </SegmentCategorySeparator>
              <SegmentCategory type={'span'} fontSize={'small'}>
                {translateText(bundle, appliedTactic.category)}
              </SegmentCategory>
            </SegmentRowContainer>
          ),
          id: null,
          category: null,
        },
      ];

    return legacyAudiencesGroups.map(
      (legacyAudienceGroup, audienceGroupIndex) => {
        const audiences = legacyAudienceGroup.segments
          ? orderSegments(legacyAudienceGroup.segments).map(
              (audienceSegment, audienceSegmentIndex) => ({
                widget: (
                  <Audience
                    operator={legacyAudienceGroup.operator}
                    audience={audienceSegment}
                    isNotFirstSegment={audienceSegmentIndex !== 0}
                    translationBundle={bundle}
                  />
                ),
                id: audienceSegment.id,
                category: getCategoryName(audienceGroupIndex),
              })
            )
          : [];

        if (audienceGroupIndex < legacyAudiencesGroups.length - 1) {
          const separator = {
            widget: (
              <SummaryViewStyled.LogicalOperator>
                <LocalizedText
                  translatorString={
                    AudienceOperatorEnum[legacyAudienceGroup.intrasetOperator]
                  }
                />
              </SummaryViewStyled.LogicalOperator>
            ),
            category: getCategoryName(audienceGroupIndex),
            id: audienceGroupIndex + legacyAudienceGroup.intrasetOperator,
          };
          audiences.push(separator);
        }
        return audiences;
      }
    );
  };

  const summaryRows = flatten(getSummaryRows());

  const shouldShowSuggestion = () => {
    if (twitchInInventory !== null && twitchInInventory !== undefined) {
      const isFunnelStageAwareness =
        funnelStage?.toLowerCase() === 'awareness' ||
        funnelStage?.toLowerCase() === 'awareness_v2';
      const targetedCanonicalIds = getTargetedCanonicalIds(audienceSegments);
      const shouldShowTwitchSuggestion =
        isFunnelStageAwareness &&
        !twitchInInventory &&
        (lineItemType === LineItemTypes.VIDEO ||
          lineItemType === LineItemTypes.STANDARD_DISPLAY) &&
        isTargetedAudience(targetedCanonicalIds);

      if (shouldShowTwitchSuggestion) {
        logTwitchSuggestionEvent({
          metricName: 'UPDATE_INVENTORY_SHOWN',
          segmentIds: targetedCanonicalIds,
          lineItemType,
          advertiserId,
          lineItemId,
        });
        return (
          <TwitchSuggestion
            setShowTwitchSnackbar={setShowTwitchSnackbar}
            setTwitchInInventory={setTwitchInInventory}
            targetedCanonicalIds={targetedCanonicalIds}
            lineItemType={lineItemType}
          />
        );
      }
    }
    return '';
  };

  const summaryViewProps = {
    summaryRows,
    summarySectionTitle: translateText(
      bundle,
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_SELECTED
    ),
    categories: getCategories(),
    id: textInputId,
    icon: SummaryViewIcon.ACCEPTED,
    additionalInformation: shouldShowSuggestion(),
  };

  const FeatureRowValue = summaryRows.length ? (
    <>
      <SummaryView {...summaryViewProps} />
      {isEntityAllowListed && isAudienceIncompatible && (
        <SummaryViewStyled.Alert>
          <SummaryViewStyled.AlertIcon
            type="exclamation-triangle"
            size="sm"
            blockSize={true}
          />
          <Text>
            <LocalizedText
              translatorString={
                TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REVIEW_AND_UPDATE
              }
            />
          </Text>
        </SummaryViewStyled.Alert>
      )}
      {shouldShowAudienceTargetingMatchTypeCheckbox(
        advertiserId,
        isAudienceTargetingMatchTypeAllowListed,
        audienceSegments
      ) && (
        <AudienceTargetingMatchTypeCheckbox
          pageState={pageState}
          initialAudienceTargetingMatchType={audienceTargetingMatchType}
        />
      )}
      {showTwitchSnackbar && (
        <TwitchInventoryUpdatedSnackbar
          setShowTwitchSnackbar={setShowTwitchSnackbar}
        />
      )}
    </>
  ) : (
    <Text>
      <LocalizedText
        translatorString={
          TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_ADD_AUDIENCES
        }
      />
    </Text>
  );

  return (
    <ThemeProvider>
      <FeatureRow
        title={
          <FeatureTitleParent>
            <FeatureTitleWrapper>
              <FeatureTitle
                htmlFor={textInputId}
                label={translateText(
                  bundle,
                  TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE
                )}
              />
            </FeatureTitleWrapper>
          </FeatureTitleParent>
        }
        value={FeatureRowValue}
      />
    </ThemeProvider>
  );
}

export default SummaryViewContainer;
